.main-container-admin {
  width: 100%;
  display: flex;
}

.sub-container-admin {
  width: 100%;
  background-color: #f0f0f0;
}

