.main-container-trainer-view {
  width: 100%;
  display: flex;
  justify-content: center;
}

.main-container-trainer-view ul {
  color: #777777;
  font-family: 'Montserrat';
}

.main-container-trainer-view li {
  padding: 3px;
}

.sub-container-trainer-view {
  width: 80%;
}

.loading-container-trainer-view {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

/* TOP BANNER */

.container-trainer-view {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.container-trainer-view h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333333;
}

.container-trainer-view svg {
  font-size: 35px;
  color: #006caa;
  padding: 20px;
}

.subtitle-trainer-view {
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
  text-align: center;
}

.subtitle-trainer-view h4 {
  font-family: 'Montserrat';
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
}

.container-content-trainer-view {
  display: flex;
  padding-bottom: 100px;
  flex-wrap: wrap;
}

.container-content-trainer-view div:last-child {
  flex: 1;
}

.container-content-trainer-view img {
  width: 300px;
  border-radius: 10px;
  margin-right: 50px;
}

.container-content-trainer-view h3 {
  font-size: 20px;
  font-family: 'Montserrat';
  color: #333333;
}

.container-content-trainer-view h4 {
  font-size: 17px;
  font-family: 'Montserrat';
  color: #333333;
  font-weight: 400;
}

.container-content-trainer-view p {
  font-family: 'Montserrat';
  color: #777777;
}

/* media queries */

@media (max-width: 1000px) {
  .title-banner-formations {
    font-size: 42px;
  }
}

@media (max-width: 800px) {
  .container-content-trainer-view div:first-child {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .container-content-trainer-view img {
    margin: 0px;
  }

  .container-content-trainer-view h3,
  .container-content-trainer-view h4 {
    text-align: center;
  }
}
