.main-container-order-steps {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-order-steps {
  width: 100%;
}

.next-button-order-steps {
  display: flex;
  justify-content: space-between;
  padding: 32px;
}

@media (max-width: 500px) {
  .MuiStepLabel-labelContainer {
    display: none !important;
  }
}
