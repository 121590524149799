.main-container-sepa-confirmation {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-sepa-confirmation {
  width: 80%;
  box-shadow: 0px 0.1px 0.3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 20%);
  border-radius: 4px;
  padding: 16px;
  margin: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sub-container-sepa-confirmation span {
  color: #1976d2;
  font-weight: 500;
}

.sub-container-sepa-confirmation > div:first-child {
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
}

.sub-container-sepa-confirmation svg {
  color: #1976d2;
  font-size: 30px;
}

.main-content-sepa-confirmation {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.main-content-sepa-confirmation > div {
  width: 100%;
  padding: 8px;
}

.main-content-sepa-confirmation {
  width: 100%;
  padding: 8px;
}

.link-sepa-confirmation {
  display: block;
}

.link-sepa-confirmation a {
  transition: ease-in-out 0.2s all;
  text-decoration: none;
  color: #969696;
  padding-left: 6px;
}

.link-sepa-confirmation a:hover {
  transition: ease-in-out 0.2s all;
  color: black;
}

.table-rib-sepa-confirmation {
  padding: 16px;
}

.table-rib-sepa-confirmation table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.table-rib-sepa-confirmation table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.table-rib-sepa-confirmation table tr {
  border: 1px solid #ddd;
  padding: 0.35em;
}

.table-rib-sepa-confirmation table th,
.table-rib-sepa-confirmation table td {
  padding: 0.625em;
  text-align: center;
}

.table-rib-sepa-confirmation table td {
  color: #999999;
  font-family: 'MontSerrat';
  font-size: 15px;
  font-weight: 500;
}

.table-rib-sepa-confirmation table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: 'Montserrat';
  color: #555;
}

.container-circularprogress-sepa-confirmation {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .sub-container-sepa-confirmation {
    width: auto;
  }

  .table-rib-sepa-confirmation table {
    border: 0;
  }

  .table-rib-sepa-confirmation table caption {
    font-size: 1.3em;
  }

  .table-rib-sepa-confirmation table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table-rib-sepa-confirmation table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .table-rib-sepa-confirmation table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .table-rib-sepa-confirmation table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a .table-rib-sepa-confirmation table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .table-rib-sepa-confirmation table td:last-child {
    border-bottom: 0;
    height: 16px;
  }
}
