/* TOP BANNER */

.container-banner-presentation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.container-banner-presentation h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333333;
}

.container-banner-presentation svg {
  font-size: 35px;
  color: #006caa;
  padding: 20px;
}

.subtitle-banner-presentation {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
  text-align: center;
}

.subtitle-banner-presentation h4 {
  width: 80%;
  font-family: 'Montserrat';
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
}

/*  MAIN CONTAINER */

.main-container-presentation {
  width: 100%;
}

.sub-container-presentation {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* CONTENT */

.content-container-presentation {
  width: 80%;
}

.title-banner-presentation {
  color: white;
  font-style: MontSerrat;
  font-size: 52px;
  text-transform: uppercase;
  font-weight: 600;
}

.title-content-presentation h2 {
  text-transform: uppercase;
  font-size: 30px;
  font-family: MontSerrat;
  font-weight: 700;
  color: #333;
}

.title-content-presentation h3 {
  font-size: 18px;
  font-family: MontSerrat;
  color: #999999;
}

.first-content-presentation p {
  font-size: 16px;
  font-family: MontSerrat;
  color: #777777;
  margin: 0px;
  font-weight: 500;
}

.images-container-presentation {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px 0px 50px 0px;
}

.images-container-presentation img {
  margin-left: 20px;
  width: 600px;
}

.button-presentation-page {
  display: flex;
  padding: 50px 0px 100px 0px;
  justify-content: center;
  width: 100%;
}

.button-presentation-page a {
  text-decoration: none;
}

.icon-award-presentation {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #c4c4c4;
}

.icon-award-presentation svg {
  font-size: 36px;
  padding: 20px 0px 30px 0px;
}

.image-container-bottom-page {
  display: flex;
  justify-content: center;
  padding: 50px 0px 50px 0px;
}

.image-container-bottom-page img {
  width: 100%;
  max-width: 500px;
}

.accordion-container-presentation-page {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.accordion-sub-presentation-page {
  width: 80%;
}

.makeStyles-heading-2 {
  font-weight: 500 !important;
  font-family: 'Montserrat' !important;
  font-size: 17px !important;
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: 'Montserrat' !important;
}

.MuiAccordionSummary-root {
  background-color: whitesmoke !important;
}

.MuiAccordionDetails-root {
  background-color: whitesmoke !important;
}

/* media queries */

@media (max-width: 1000px) {
  .title-banner-presentation {
    font-size: 42px;
  }
  .images-container-presentation img {
    margin-top: 20px;
  }

  .images-container-presentation img {
    margin-right: 25px;
  }
}

@media (max-width: 800px) {
  .title-banner-presentation {
    font-size: 32px;
  }
  .content-container-presentation {
    width: 90%;
  }
}

@media (max-width: 700px) {
  .title-content-presentation h2 {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  .title-banner-presentation {
    font-size: 22px;
  }
  .banner-container-presentation {
    height: 178px;
  }
  .button-presentation-page a {
    font-size: 10px;
  }
}
