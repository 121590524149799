.main-container-credit-card-confirmation {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-credit-card-confirmation {
  width: 80%;
  box-shadow: 0px 0.1px 0.3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 20%);
  border-radius: 4px;
  padding: 16px;
  margin: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sub-container-credit-card-confirmation > div:first-child {
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
}

.sub-container-credit-card-confirmation span {
  color: #1976d2;
  font-weight: 500;
}

.sub-container-credit-card-confirmation svg {
  color: #1976d2;
  font-size: 30px;
}

.main-content-credit-card-confirmation {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.main-content-credit-card-confirmation > div {
  width: 100%;
  padding: 8px;
}

.main-content-credit-card-confirmation {
  width: 100%;
  padding: 8px;
}

.link-credit-card-confirmation {
  display: block;
}

.link-credit-card-confirmation a {
  transition: ease-in-out 0.2s all;
  text-decoration: none;
  color: #969696;
  padding-left: 6px;
}

.link-credit-card-confirmation a:hover {
  transition: ease-in-out 0.2s all;
  color: black;
}
