.main-container-add-training {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-add-training {
  width: 80%;
  padding: 32px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
  margin: 16px 0px;
  background-color: white;
}

.info-add-training {
  padding: 16px;
}

.admin-add-training-delete-date-button {
  display: flex;
  align-items: center;
}

.info-add-training div:first-child {
  margin-top: 32px;
}

.main-container-add-training h4 {
  font-size: 16px;
  line-height: 19px;
  color: #6f6f6f;
  font-family: 'Montserrat';
  margin: 0;
  margin-bottom: 30px;
  font-weight: 400;
  text-transform: capitalize;
}

.add-image-add-traning {
  padding: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.add-image-add-traning h4 {
  width: 100%;
}

.add-image-add-traning input {
  display: none !important;
}

.add-image-add-traning img {
  border-radius: 5px;
  max-width: 350px;
  max-height: 350px;
}

.add-image-add-traning img:hover {
  cursor: pointer;
}

.drag-drop-add-training {
  width: 201px;
  height: 115px;
  left: 462px;
  top: 467px;
  background: #e6f2ff;
  border: 1px dashed #88bdf7;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-button-add-training {
  padding: 16px;
}

.container-date-picker-add-training {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#addButton {
   display: flex;
  justify-content: center;
}

.pdf-name-add-training {
  cursor: pointer;
  font-family: 'Montserrat';
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: #757575;
}

.container-input-middle-add-training {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.container-input-middle-add-training > div {
  width: 40%;
}
/* 
.MuiInputLabel-outlined {
  z-index: 999999 !important;
} */

.add-targets-add-training {
  padding: 16px;
}

.add-description-add-training {
  padding: 16px;
}

.container-files-table-add-training {
  padding: 16px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.content-file-table-add-training {
  padding: 8px;
}

.content-file-table-add-training svg {
  margin-left: 10px;
  color: #1976d2;
  cursor: pointer;
}

textarea {
  width: 99%;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.submit-button-container-add-training {
  text-align: center;
  margin-top: 32px;
}

@media (max-width: 1000px) {
  .sub-container-add-training {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .sub-container-add-training {
    width: 90%;
  }
}

@media (max-width: 550px) {
  .sub-container-add-training {
    width: 100%;
    margin: 16px;
    padding: 16px;
  }

  .container-input-middle-add-training > div {
    width: 100%;
  }

  .container-input-middle-add-training > div:first-child {
    padding-bottom: 32px;
  }
}
