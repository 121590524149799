.main-container-edit-training {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-edit-training {
  width: 80%;
  padding: 32px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
  margin: 16px 0px;
  background-color: white;
}

.info-edit-training {
  padding: 16px;
}

.info-edit-training div:first-child {
  margin-top: 32px;
}

.main-container-edit-training h4 {
  font-size: 16px;
  line-height: 19px;
  color: #6f6f6f;
  font-family: 'Montserrat';
  margin: 0;
  margin-bottom: 30px;
  font-weight: 400;
  text-transform: capitalize;
}

.add-image-edit-training {
  padding: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.add-image-edit-training h4 {
  width: 100%;
}

.add-image-edit-training input {
  display: none !important;
}

.add-image-edit-training img {
  border-radius: 5px;
  max-width: 350px;
  max-height: 350px;
}

.add-image-edit-training img:hover {
  cursor: pointer;
}

.drag-drop-edit-training {
  width: 201px;
  height: 115px;
  left: 462px;
  top: 467px;
  background: #e6f2ff;
  border: 1px dashed #88bdf7;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-button-edit-training {
  padding: 16px;
}

.container-date-picker-edit-training {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pdf-name-edit-training {
  cursor: pointer;
  font-family: 'Montserrat';
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: #757575;
}

.css-yk16xz-control {
  min-height: 56px !important;
}

.css-1pahdxg-control {
  min-height: 56px !important;
}

.container-input-middle-edit-training {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.container-input-middle-edit-training > div {
  width: 40%;
}

.container-files-table-edit-training {
  padding: 16px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.content-file-table-edit-training {
  transition: ease-in-out 0.2s all;
  padding: 8px;
  color: #737373;
}

.container-files-table-edit-training a {
  transition: ease-in-out 0.2s all;
  text-decoration: none;
  color: #737373;
}

.content-file-table-edit-training a:hover {
  transition: ease-in-out 0.2s all;
  color: #1976d2;
}

.content-file-table-edit-training svg {
  margin-left: 10px;
  color: #1976d2;
  cursor: pointer;
}


.upload-pdf-container-edit-training {
  padding: 8px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-family: 'Montserrat';
  color: #737373;
}

.upload-pdf-container-edit-training svg {
  margin-left: 10px;
  color: #1976d2;
}

.add-targets-edit-training {
  padding: 16px;
}

.add-description-edit-training {
  padding: 16px;
}

textarea {
  width: 99%;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.submit-button-container-edit-training {
  text-align: center;
  margin-top: 32px;
}

@media (max-width: 1000px) {
  .sub-container-edit-training {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .sub-container-edit-training {
    width: 80%;
  }
}

@media (max-width: 550px) {
  .sub-container-edit-training {
    width: 100%;
    margin: 16px;
    padding: 16px;
  }

  .container-input-middle-edit-training > div {
    width: 100%;
  }

  .container-input-middle-edit-training > div:first-child {
    padding-bottom: 32px;
  }
}
