.main-container-landing-page-user {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.container-register-login-landing-page-user {
  width: 100%;
  padding: 50px 0px 100px 0px;
  display: flex;
  justify-content: center;
}

.container-client-account-landing-page-user {
  height: 100%;
}


/* media queries */

@media (max-width: 1000px) {
  .title-banner-landing-page-user {
    font-size: 42px;
  }
}

@media (max-width: 800px) {
  .title-banner-landing-page-user {
    font-size: 32px;
  }
} 

@media (max-width: 750px) {
  .container-register-login-landing-page-user {
    flex-wrap: wrap;
  }
} 

@media (max-width: 500px) {
  .title-banner-landing-page-user {
    font-size: 22px;
  }

  .banner-container-landing-page-user {
    height: 178px;
  }
}

.main-container-client-account {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-wrap: wrap;
}

.sub-container-client-account {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
