.main-container-user-credential {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 300px;
  align-items: center;
}

.sub-container-user-credential {
  width: 100%;
  text-align: center;
}

.content-user-credential {
  padding: 16px;
}

.sub-container-user-credential h3 {
  margin: 0px;
  padding: 16px;
  font-family: 'Montserrat';
  color: #999999;
}
