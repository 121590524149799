.main-container-order-payment {
  width: 100%;
}

.sub-container-order-payment {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.container-payment-element-order-payment {
  width: 80%;
  box-shadow: 0px 0.1px 0.3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 20%);
  margin: 16px;
  border-radius: 5px;
}
