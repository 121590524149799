.main-container-menu-mobile-displayer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-menu-mobile-displayer {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: #1976de;
}

.sub-container-menu-mobile-displayer svg {
  font-size: 15px;
  color: antiquewhite;
  padding-left: 10px;
}

#simple-menu a {
  text-decoration: none;
  font-family: 'MonteSerrat';
  color: #333333;
}

.log-out-button-menu-mobile-drawer {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 6px 16px;
}

.tatat {
  color: pink;
}

.title-menu-mobile-displayer {
  padding-left: 20px;
  text-transform: initial;
  font-family: 'Montserrat';
  color: antiquewhite;
  font-size: 16px;
  font-weight: 400;
}
