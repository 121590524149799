.main-container-banner {
  width: 100%;
  display: flex;
  min-height: 340px;
  background-color: #0662c5;
  justify-content: space-between;
}

.icon-content-banner {
  display: flex;
  justify-content: center;
}

.text-container-banner {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.text-content-banner {
  width: 70%;
  text-align: center;
}

.icon-content-banner svg {
  font-size: 70px;
  color: #ffff;
}

.container-content-banner {
  display: flex;
  align-items: center;
  color: #ffff;
  font-size: 18px;
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-weight: 500;
}

.main-container-banner div:hover {
  transition: all 0.1s ease-out;
  background-color: #4458c9;
}

@media (max-width: 800px) {
  .main-container-banner {
    flex-wrap: wrap;
    height: 100%;
  }

  .container-content-banner {
    display: block;
    width: 100%;
    padding: 40px 0px 40px 0px;
  }
}
