.main-container-order-check-element {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-order-check-element {
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
}

.content-order-check-element span {
  font-weight: 700;
}

.content-order-check-element ul {
  font-weight: 600;
}

.general-terms-container-check-element {
  display: flex;
  align-items: center;
  padding: 16px;
}

.general-terms-container-check-element > div {
  width: 100%;
  display: flex;
}

.general-terms-container-check-element > div > div {
  font-family: Montserrat;
  font-size: 16px;
}

.general-terms-container-check-element a {
  transition: ease-in-out 0.2s all;
  text-decoration: none;
  color: #969696;
  padding-left: 6px;
}

.general-terms-container-check-element a:hover {
  transition: ease-in-out 0.2s all;
  color: black;
}

.error-checkbox-check-element {
  color: red;
}

.error-checkbox-check-element a {
  color: red;
}

.container-alert-check-element {
  padding: 16px;
}

.container-button-check-element {
  text-align: center;
  padding: 16px;
}

.privacy-policy-check-element a {
  transition: ease-in-out 0.2s all;
  text-decoration: none;
  color: #969696;
  padding-left: 5px;
}

.privacy-policy-check-element a:hover {
  transition: ease-in-out 0.2s all;
  color: black;
}
