.main-container-admin-add-order {
  display: flex;
  justify-content: center;
}

.sub-container-admin-add-order {
  box-shadow: 0px 2px 7px rgb(0 0 0 / 25%);
  width: 50%;
  border-radius: 5px;
  padding: 16px;
  background-color: white;
  margin: 16px;
}

.container-content-admin-add-order {
  padding: 16px;
}

.container-content-admin-add-order:last-child {
  text-align: center;
}

.main-title-admin-add-order {
  color: #9e9e9e;
  font-size: 18px;
  font-family: 'MontSerrat';
  padding: 16px;
  font-weight: 800;
}

.container-pdf-input-add-order {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-title-add-order {
  text-align: center;
  font-family: 'MontSerrat';
}

.container-pdf-input-add-order input {
  display: none;
}
