.main-container-order-contract {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-order-contract {
  width: 55%;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 5px;
  margin: 16px;
}

.sub-container-order-contract > div > img {
  width: 200px;
  padding: 16px;
}

.handicap-selection-contract {
  font-size: 16px;
  margin-bottom: 12px;
}

.container-img-order-contract {
  padding: 16px;
  text-align: center;
}

.fist-container-order-contract {
  font-family: 'Arial';
  padding: 16px;
  color: #444444;
  font-size: 14px;
  text-align: center;
}

.fist-container-order-contract div {
  padding: 3px;
}

.second-container-order-contract {
  font-family: 'Arial';
  text-align: center;
  font-size: 14px;
  padding: 16px;
}

.second-container-order-contract > div:first-child > p:first-child {
  font-weight: 700;
  color: #444444;
}

.second-container-order-contract div:last-child {
  margin-top: 13px;
}

.second-container-order-contract p {
  margin: 0px;
}

.third-container-order-contract {
  padding: 16px;
  font-weight: 700;
  color: #444444;
  font-family: 'Arial';
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.third-container-order-contract p:last-child {
  font-size: 14px;
  font-weight: 500;
  width: 90%;
}

.fourth-container-order-contract {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: 'Arial';
  font-size: 14px;
  color: #444444;
  font-weight: 500;
  padding: 16px;
}

.fourth-container-order-contract div {
  text-align: center;
  padding: 4px;
  width: 100%;
}

.fourth-container-order-contract div:first-child {
  text-transform: uppercase;
  font-weight: 700;
}

.fifth-container-order-contract {
  text-align: center;
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Arial';
  font-size: 14px;
  font-weight: 500;
  color: #444444;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.fifth-container-order-contract > div:first-child {
  width: 100%;
  font-weight: 700;
  padding: 8px;
}

.dynamic-content-order-contract {
  width: 90%;
}

.sixth-container-order-contract {
  font-family: 'Arial';
  font-weight: 500;
  font-size: 14px;
}

.sixth-container-order-contract > div:first-child {
  text-align: center;
  padding: 16px;
}

.sixth-container-order-contract > div:not(:first-child) {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 16px;
}

.sixth-container-order-contract img {
  width: 150px;
}

.container-submit-button-order-contract {
  padding: 32px;
  text-align: center;
}

.container-error-order-contract {
  padding: 16px;
}

.signature-container-order-contract div:first-child {
  flex: 1;
}

.signature-container-order-contract div:last-child {
  flex: 1;
}

.signature-container-order-contract div:last-child p {
  margin: 0;
  padding: 6px;
}

.error-checkbox-order-contract p {
  color: #f44336;
  text-align: center;
}

.name-date-signature-order-contract {
  padding: 6px;
  color: #757575;
}

.container-pdf-display-none {
  display: none;
}

.container-client-sign-display-none {
  display: none;
}

.container-circular-progress {
  text-align: center;
  min-height: 100px;
}

@media (max-width: 1200px) {
  .sub-container-order-contract {
    width: 70%;
  }
}

@media (max-width: 1000px) {
  .sub-container-order-contract {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .sub-container-order-contract {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .signature-container-order-contract div {
    flex: none !important;
  }

  .signature-iffco-order-contract {
    padding-top: 32px;
  }
}
