.main-container-drawer {
  width: 100%;
  height: 100%;
}

.logo-drawer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
}

.logo-drawer-container svg {
  padding: 7px 7px 7px 7px;
  color: #999999;
  margin: 20px;
  font-size: 20px;
}

.nested-links-drawer {
  position: relative;
}

.nested-links-drawer svg {
  transition: ease-in-out 0.2s all;
  position: absolute;
  left: 210px;
}

.main-div-drawer {
  height: 100%;
  background-color: #1f2227;
}

.logo-drawer-container img {
  width: 150px;
  padding: 5px 20px 5px 0px;
}

.dropdown-content-drawer {
  background-color: #282b30;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s;
}

.show-dropdown-content-drawer {
  max-height: 138px;
}

.drop-down-drawer {
  width: 250px;
}

.drop-down-drawer a,
.nested-links-drawer {
  text-decoration: none;
  color: antiquewhite;
  font-size: 12px;
  font-family: MontSerrat;
  padding: 15px 0px 15px 0px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
}

.log-out-button-drawer {
  text-decoration: none;
  color: antiquewhite;
  font-size: 12px;
  font-family: MontSerrat;
  padding: 15px 0px 15px 0px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
}

.log-out-button-drawer a {
  text-decoration: none;
  color: antiquewhite;
}

.rotate-chevron-drawer svg {
  transition: ease-in-out 0.2s all;
  transform: rotate(180deg);
}

.close-icon-drawer {
  height: 31px;
  padding: 7px 0px 7px 0px;
  display: flex;
  justify-content: center;
}

.drop-down-drawer a:hover,
.nested-links-drawer:hover {
  background-color: #0662c5;
}

.social-network-icons-container-drawer {
  font-size: 37px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.facebook-icon-drawer {
  color: #4867aa;
  width: 33px !important;
}

.linkedin-icon-drawer {
  color: #4995cd;
  width: 33px !important;
  padding-left: 50px;
}
