.main-container-how-to-reserve {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* TOP BANNER */

.container-banner-how-to-reserve {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
}

.container-banner-how-to-reserve h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333333;
}

.container-banner-how-to-reserve svg {
  font-size: 35px;
  color: #006caa;
  padding: 20px;
}

.subtitle-banner-how-to-reserve {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
}

.subtitle-banner-how-to-reserve h4 {
  font-family: 'Montserrat';
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
  width: 80%;
}

.accordion-container-how-to-reserve {
  width: 80%;
  padding-bottom: 50px;
}

/* media queries */

@media (max-width: 1000px) {
  .title-banner-how-to-reserve {
    font-size: 42px;
  }
}

@media (max-width: 800px) {
  .title-banner-how-to-reserve {
    font-size: 32px;
  }
}

@media (max-width: 500px) {
  .title-banner-how-to-reserve {
    font-size: 22px;
  }

  .accordion-container-how-to-reserve {
    width: 90%;
    padding-bottom: 50px;
  }

  .container-banner-how-to-reserve h2 {
    font-size: 14px;
  }

  .banner-container-how-to-reserve {
    height: 178px;
  }
}
