.StripeElement {
  width: auto !important;
  padding: 20px 12px !important;
  height: auto !important;
}

.container-text-field-card-payment {
  padding: 16px;
}

.container-card-element {
  padding: 16px;
}

.container-submit-cardpayment-button {
  text-align: center;
}

.container-submit-cardpayment-button {
  padding: 32px 16px 16px 16px;
}

.container-alert-card-element {
  padding: 16px;
}

.card-logo-card-element {
  padding: 16px;
}

.card-logo-card-element img {
  width: 30px;
  padding-right: 10px;
}

.content-order-card-element {
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
}

.general-terms-container-card-element {
  display: flex;
  align-items: center;
  padding: 16px;
}

.general-terms-container-card-element > div {
  width: 100%;
  display: flex;
}

.general-terms-container-card-element > div > div {
  font-family: Montserrat;
  font-size: 16px;
}

.general-terms-container-card-element a {
  transition: ease-in-out 0.2s all;
  text-decoration: none;
  color: #969696;
  padding-left: 6px;
}

.general-terms-container-card-element a:hover {
  transition: ease-in-out 0.2s all;
  color: black;
}

.error-checkbox-card-element {
  color: red;
}

.error-checkbox-card-element a {
  color: red;
}

.content-order-card-element a {
  transition: ease-in-out 0.2s all;
  text-decoration: none;
  color: #969696;
  padding-left: 5px;
}

.content-order-card-element a:hover {
  transition: ease-in-out 0.2s all;
  color: black;
}
