.main-container-user-account-details {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-user-account-details {
  box-shadow: 0px 2px 7px rgb(0 0 0 / 25%);
  width: 500px;
  border-radius: 5px;
  margin: 16px;
  padding: 16px;
}

.validation-message-account-details {
  padding: 16px;
  font-family: 'Montserrat';
}

.container-content-account-details {
  padding: 16px;
}

.container-content-account-details h3 {
  font-size: 16px;
  margin: 0px;
  padding: 16px;
  text-align: center;
  font-family: 'Montserrat';
  color: #333333;
}

.content-account-details {
  padding: 16px;
  text-align: center;
}
