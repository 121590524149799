.main-container-banner-contact {
  display: flex;
  width: 80%;
  box-sizing: border-box;
  padding: 16px;
  margin: 16px;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #f7f7f7;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
}

.main-content-banner-contact div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.svg-icon-banner-contact {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
}

.svg-icon-banner-contact svg {
  color: #006caa;
  font-size: 30px;
}

.main-content-banner-contact {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
  text-align: center;
  justify-content: center;
}

.main-container-banner-contact div {
  padding: 16px;
}

.main-content-banner-contact h3 {
  font-family: 'Montserrat';
  font-size: 30px;
  margin: 0;
  padding: 16px;
  color: #333333;
}

.main-content-banner-contact h4 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 16px;
  line-height: 1.625;
  color: #777777;
  width: 100%;
  font-family: 'Montserrat';
}

.main-content-banner-contact span:last-child {
  margin-left: 15px;
}

.main-content-banner-contact a {
  text-decoration: none;
}

@media (max-width: 800px) {
  .main-container-banner-contact {
    width: 90%;
  }

  .main-container-banner-contact h3 {
    font-size: 25px;
  }
}
