.main-container-admin-orders {
  padding: 16px;
}

.MuiDataGrid-root {
  background-color: white !important;
}

.new-order-icon-admin-order svg {
  color: rgb(85, 151, 85);
}

.not-new-order-icon-admin-order svg {
  color: lightgray;
}

.order-table-row {
  font-family: 'Montserrat';
  color: #333333;
}

.MuiChip-label {
  min-width: 85px;
  text-align: center;
}

.confirmed-chip-admin-order {
  color: rgb(85, 151, 85) !important;
}

.pending-chip-admin-order {
  color: rgb(221, 208, 30) !important;
}

.canceled-chip-admin-order {
  color: rgb(184, 60, 60) !important;
}

.order-table-links a {
  transition: ease-in-out 0.2s all;
  font-family: 'Montserrat';
  color: #676767;
  text-decoration: none;
}

.order-table-links a:hover {
  transition: ease-in-out 0.2s all;
  color: #1962c6;
}
