.breadcrumbs-container {
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
}

.breadcrumbs-container a {
  text-decoration: none;
  color: #777777;
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-size: 14px;
}

.breadcrumbs-container li:last-child {
  font-weight: 500;
}
