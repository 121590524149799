.main-container-footer {
  padding: 100px;
  display: flex;
  background-color: #25282e;
  margin: 0;
  width: 100%;
  padding: 50px 0px 50px 0px;
  justify-content: center;
}

.sub-container-footer {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.left-side-container-footer {
  width: 45%;
}

.right-side-container-footer {
  width: 30%;
  display: flex;
  justify-content: center;
}

.description-container-footer {
  display: flex;
  width: 100%;
}

.title-container-displayer-footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.title-pic-displayer-footer {
  color: #9f9fa0;
  font-size: 18px;
  font-family: MontSerrat;
  padding-bottom: 30px;
  font-weight: 500;
  margin-left: 15px;
}

.description-text-footer {
  margin-top: 25px;
  font-family: MontSerrat;
  color: #aaa;
  font-weight: 400;
  max-width: 600px;
  font-size: 16px;
}

.social-network-icons-container-footer {
  font-size: 37px;
  display: flex;
  margin-top: 25px;
}

.facebook-icon-footer a {
  color: #4867aa;
}

.facebook-icon-footer svg {
  cursor: pointer;
}

.linkedin-icon-footer svg {
  cursor: pointer;
}

.linkedin-icon-footer a {
  color: #4995cd;
  padding-left: 10px;
}

/* media queries */

@media (max-width: 800px) {
  .sub-container-footer {
    flex-wrap: wrap;
    width: 90%;
  }

  .left-side-container-footer {
    flex: none;
    width: 100%;
  }

  .right-side-container-footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .title-container-displayer-footer {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: center;
    padding-top: 30px;
  }

  .main-container-pic-displayer-footer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .right-side-container-footer {
    display: none;
  }

  .social-network-icons-container-footer {
    justify-content: center;
  }

  .logo-container-footer {
    display: flex;
    justify-content: center;
  }

  .description-text-footer {
    font-size: 12px;
    font-family: MontSerrat;
    color: #5a5858;
    font-weight: 600;
    max-width: 600px;
    padding: 15px 25px 15px 25px;
    background-color: rgb(248, 248, 248);
    border-radius: 13px;
    line-height: 22px;
    box-shadow: 0 12px 17px #272727;
  }

  .linkedin-icon-footer {
    padding-left: 40px;
  }
}
