.main-container-error-payment {
  width: 100%;
  height: 500px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-container-error-payment {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #333333;
  font-family: 'Montserrat';
}

.sub-container-error-payment svg {
  width: 100%;
  font-size: 150px;
}

.sub-container-error-payment h3 {
  width: 100%;
  text-align: center;
  font-size: 50px;
  margin: 0;
  padding: 16px;
}

.sub-container-error-payment h4 {
  width: 100%;
  text-align: center;
  color: lightgray;
  font-family: 'Montserrat';
  font-weight: 500;
  margin: 0;
  font-size: 21px;
}

.sub-container-error-payment p {
  margin: 0;
  text-align: center;
  padding: 16px;
  font-size: 20px;
}

.sub-container-error-payment p > span {
  color: #1972c6;
}

.container-button-error-payment {
  width: 100%;
  text-align: center;
}

.container-button-error-payment a {
  text-decoration: none;
}

