.main-container-salesterms {
  width: 100%;
}

.banner-container-salesterms {
  width: 100%;
  height: 278px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-container-salesterms {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-container-salesterms {
  width: 80%;
}

.content-container-salesterms li {
  font-size: 16px;
  font-family: 'Montserrat';
  color: #454545;
}

.title-banner-salesterms {
  color: white;
  font-style: MontSerrat;
  font-size: 52px;
  text-transform: uppercase;
  font-weight: 600;
}


.title-content-salesterms h2 {
  text-transform: uppercase;
  font-size: 20px;
  font-family: MontSerrat;
  font-weight: 700;
  color: #777777;
}

.title-content-salesterms h3 {
  font-size: 16px;
  font-family: MontSerrat;
  color: #777777;
}

.content-salesterms p {
  font-size: 16px;
  font-family: MontSerrat;
  color: #404040;
  margin: 0px;
  font-weight: 400;
  line-height: 1.625;
}

.content-first-child-salesterms {
  font-size: 16px;
  font-family: MontSerrat;
  color: #404040;
  margin: 0px;
  font-weight: 400;
  line-height: 1.625;
}

.last-child-content-salesterms {
  padding-bottom: 100px;
}

/* media queries */

@media (max-width: 800px) {
  .content-container-salesterms {
    width: 90%;
  }
}

@media (max-width: 700px) {
  .title-content-presentation h2 {
    font-size: 20px;
  }
}

@media (max-width: 1000px) {
  .title-banner-salesterms {
    font-size: 42px;
  }
}

@media (max-width: 800px) {
  .title-banner-salesterms {
    font-size: 32px;
  }
} 

@media (max-width: 500px) {
  .title-banner-salesterms {
    font-size: 18px;
  }

  .banner-container-salesterms {
    height: 178px;
  }
}