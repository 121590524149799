.main-container-current-order-displayer {
  width: 80%;
  box-shadow: 0px 0.1px 0.3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 20%);
  margin: 16px;
  border-radius: 5px;
}

.current-order-displayer-title {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  color: #464646;
  padding: 16px;
  margin: 0;
}

.main-container-current-order-displayer div {
  display: flex;
  padding: 8px 8px 8px 32px;
  align-items: baseline;
}

.main-container-current-order-displayer div:last-child {
  padding-bottom: 16px;
}

.main-container-current-order-displayer h3 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  color: #1976d2;
  margin: 0;
}

.main-container-current-order-displayer p {
  margin: 0;
  padding-left: 10px;
  color: #464646;
}

.main-container-current-order-displayer h4 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  margin: 0;
  padding-right: 10px;
}
