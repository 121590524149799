.main-container-edit-trainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.main-container-edit-trainer h4 {
  font-size: 16px;
  line-height: 19px;
  color: #6f6f6f;
  font-family: 'Montserrat';
  margin: 0;
  margin-bottom: 30px;
  font-weight: 400;
  text-transform: capitalize;
}

.sub-container-edit-trainer {
  width: 50%;
  padding: 32px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
  margin: 16px 0px;
  background-color: white;
}

.info-edit-trainer {
  padding: 16px;
}

.select-profession-edit-trainer {
  padding: 0px 16px 16px 16px;
}

.add-image-edit-trainer {
  padding: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.add-image-edit-trainer h4 {
  width: 100%;
}

.add-image-edit-trainer img {
  border-radius: 5px;
  max-width: 350px;
  max-height: 350px;
  width: 100%;
}

.add-image-edit-trainer img:hover {
  cursor: pointer;
}

.drag-drop-edit-trainer {
  width: 201px;
  height: 115px;
  left: 462px;
  top: 467px;
  background: #e6f2ff;
  border: 1px dashed #88bdf7;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-name-edit-trainer {
  width: 100%;
  text-align: center;
}

.add-image-edit-trainer input {
  display: none !important;
}

.add-image-edit-trainer svg {
  font-size: 26px;
  color: #1976d2;
}

.add-description-edit-trainer {
  padding: 16px;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-class {
  height: 500px;
  padding: 1rem;
  border: 1px solid #ccc;
}

.toolbar-class {
  border: 1px solid #ccc;
}

.submit-button-container-edit-trainer {
  text-align: center;
  padding: 20px 0px;
}

.container-circular-progress-edit-trainer {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1000px) {
  .sub-container-edit-trainer {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .sub-container-edit-trainer {
    width: 80%;
  }
}

@media (max-width: 550px) {
  .sub-container-edit-trainer {
    width: 100%;
    margin: 16px;
    padding: 16px;
  }
}
