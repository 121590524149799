.main-container-admin-add-user {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-admin-add-user {
  border-radius: 5px;
  width: 50%;
  padding: 16px;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 25%);
  margin: 16px;
}

.main-title-admin-add-user {
  color: #9e9e9e;
  font-size: 18px;
  font-family: 'MontSerrat';
  padding: 16px;
  font-weight: 800;
}

.next-buttons-container-admin-add-user {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.sub-container-firstname-lastname-admin-add-user {
  width: 100%;
  max-width: 500px;
}

.sub-container-pro-name-admin-add-user {
  padding-top: 25px;
}

.container-text-field-admin-add-user {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.container-button-submit-admin-add-user {
  padding: 25px 0px 50px 0px;
}

.container-button-submit-admin-add-user a {
  text-decoration: none;
}

.container-mail-address-admin-add-user {
  padding-top: 25px;
}

.circular-progress-admin-add-user {
  width: 100%;
  text-align: center;
}

.validation-message-admin-add-user {
  padding: 16px;
}

.button-submit-admin-add-user {
  padding: 15px;
  max-width: 250px;
  text-align: center;
  font-family: MontSerrat;
  font-size: 15px;
  background: rgb(0, 116, 173);
  color: #f7f7f7;
  background: radial-gradient(circle, rgba(0, 116, 173, 1) 0%, rgba(42, 147, 196, 1) 0%, rgba(0, 116, 173, 1) 100%);
  border-radius: 6px;
}

.button-submit-admin-add-user:hover {
  background: radial-gradient(circle, rgb(0, 85, 128) 0%, rgb(27, 93, 124) 0%, rgb(1, 85, 128) 100%);
  cursor: pointer;
}

@media (max-width: 750px) {
  .main-title-admin-add-user {
    font-size: 20px;
  }
}
