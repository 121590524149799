.main-container-forgetpassword {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-forgetpassword {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-container-forgetpassword {
  width: 300px;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px 0px 50px 0px;
  padding: 10px;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 5px;
}

.container-input-forgetpassword {
  width: 80%;
}

.card-container-forgetpassword svg {
  padding-top: 40px;
  font-size: 25px;
  color: #0066b3;
}

.card-container-forgetpassword h3 {
  font-family: 'Montserrat';
  width: 100%;
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  text-align: center;
}

.button-container-forgetpassword {
  max-width: 150px;
  cursor: pointer;
  height: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0066b3;
  border-radius: 5px;
  color: antiquewhite;
  font-family: 'Montserrat';
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}

.validation-card-container-forgetpassword {
  width: 300px;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px 0px 50px 0px;
  padding: 10px;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 5px;
  align-items: center;
}

.validation-main-title-forgetpassword {
  display: flex;
  justify-content: center;
}

.validation-icon-forgetpassword svg {
  font-size: 35px;
  color: #333333;
}

.validation-card-container-forgetpassword h3 {
  font-size: 16px;
  font-family: 'Montserrat';
  color: #333333;
  font-weight: 500;
  margin: 0;
  text-align: center;
  width: 80%;
}

.validation-card-container-forgetpassword a {
  text-decoration: none;
}

.button-redirect-forgetpassword {
  max-width: 150px;
  cursor: pointer;
  height: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0066b3;
  border-radius: 5px;
  color: antiquewhite;
  font-family: 'Montserrat';
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}