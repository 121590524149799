.main-container-admin-trainings {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-container-admin-trainings {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
  background-color: #ddeffd !important;
}

.trainer-name-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}

.makeStyles-highlight-38 {
  color: #3f51b5 !important;
}

.MuiTableCell-paddingNone a {
  text-decoration: none !important;
  color: #333333;
  text-transform: capitalize;
}

.MuiTableCell-paddingNone a:hover {
  color: #3f51b5;
}

.MuiTableCell-alignRight {
  text-align: left !important;
  flex-direction: row-reverse;
  text-transform: capitalize;
}

.makeStyles-root-1 {
  width: auto !important;
}

.MuiTableCell-head {
  font-weight: 600 !important;
}

.loading-container-admin-trainings {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
