.main-container-login {
  display: flex;
  justify-content: space-around;
}

.container-login {
  width: 100%;
  max-width: 490px;
  height: 350px;
  padding: 45px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 5px;
  margin: 0px 20px 0px 20px;
}

.container-login h3 {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 15px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  color: #333;
  text-align: center;
}

.inputs-login {
  width: 100%;
}

.textfield-login {
  width: 100%;
}

.container-button-login {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 35px;
  align-items: center;
}

.container-button-login a {
  width: 100%;
  text-decoration: none;
  color: #1976d2;
  text-align: center;
}

.forgot-password-login {
  padding: 16px;
}

/* MEDIA QUERIES */

@media (max-width: 1000px) {
  .button-login {
    display: block;
  }
  .forgot-password-login {
    font-size: 14px;
    text-align: center;
  }
}

@media (max-width: 700px) {
  .main-container-login {
    flex-wrap: wrap;
  }
}
