.container-tabs-tabs-displayer {
  display: flex;
  justify-content: space-around;
  height: 50px;
  align-items: center;
  width: 100%;
}

.container-tabs-tabs-displayer a {
  text-decoration: none;
  height: 100%;
  width: 100%;
}

.title-tabs-tabs-displayer {
  text-transform: uppercase;
  font-size: 14px;
  color: #999999;
  font-weight: 500;
  font-family: 'Montserrat';
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.title-tabs-tabs-displayer:hover {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  background-color: #1976de;
  color: aliceblue;
}

.background-tabs-displayer {
  color: aliceblue;
  background-color: #1976de;
}

@media (max-width: 1200px) {
  .title-tabs-tabs-displayer {
    font-size: 13px;
  }
}

@media (max-width: 1000px) {
  .container-tabs-tabs-displayer {
    width: 100%;
  }
  .title-tabs-tabs-displayer {
    font-size: 12px;
  }
}

@media (max-width: 800px) {
  .sub-container-tabs-displayer {
    width: 90%;
  }
}
