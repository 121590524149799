.main-container-signin {
  display: flex;
  justify-content: space-around;
}

.container-signin {
  width: 100%;
  max-width: 490px;
  height: 350px;
  padding: 45px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 5px;
  margin: 0px 20px 0px 20px;
}

.container-signin h3 {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 15px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  color: #333;
  text-align: center;
}

.inputs-signin {
  width: 100%;
}

.textfield-signin {
  width: 100%;
}

.container-button-signin {
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: 35px;
  align-items: center;
}

/* MEDIA QUERIES */

@media (max-width: 750px) {
  .main-container-signin {
    padding-top: 50px;
  }
}

@media (max-width: 700px) {
  .main-container-signin {
    flex-wrap: wrap;
  }
}
