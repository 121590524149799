.main-container-topbar {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  background-color: #f0f0f0;
}

.sub-container-topbar {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.container-left-topbar {
  display: flex;
  align-items: center;
}

.container-left-topbar a {
  transition: all 0.1s ease-in;
  text-decoration: none;
  color: #999999;
}

.social-media-links {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  color: #999999;
}

.social-media-links a:hover {
  cursor: pointer;
  transition: all 0.1s ease-in;
  color: #0063c8;
}

.linkedin-link-topbar {
  border-left: none;
}

.faq-link-topbar {
  padding: 0px 6px 0px 6px;
  font-family: MontSerrat;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  border-left: none;
}

.container-right-topbar {
  display: flex;
  align-items: center;
}

.container-right-topbar a {
  text-decoration: none;
}

.admin-button-topbar {
  font-family: MontSerrat;
  color: #999999;
  padding: 0px 20px 0px 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  border-right: 1px solid #e1e1e1;
}

.login-link-topbar {
  font-family: MontSerrat;
  color: #999999;
  padding: 0px 20px 0px 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  border-right: 1px solid #e1e1e1;
}

.login-link-topbar:hover,
.admin-button-topbar:hover {
  cursor: pointer;
  transition: all 0.2s ease-in;
  color: #0063c8;
}

.loguut-link-topbar {
  font-family: MontSerrat;
  color: #999999;
  padding: 0px 20px 0px 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  border-right: 1px solid #e1e1e1;
}

.logout-link-topbar:hover {
  cursor: pointer;
  transition: all 0.2s ease-in;
  color: #0063c8;
}

.register-link-topbar {
  border-right: none;
}

/* media queries */

@media (max-width: 800px) {
  .sub-container-topbar {
    width: 90%;
  }
}
