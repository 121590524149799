.drawer {
  display: none;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

@media (max-width: 900px) {
  .header {
    display: none;
  }

  .drawer {
    display: block;
  }
}

@media (max-width: 900px) {
  .topbar {
    display: none;
  }
}
