.main-container-additional-footer{
  height: 200px;
  width: 100%;
  background-color: #1f2227;
  display: flex;
  justify-content: center;
}

.sub-container-additional-footer {
  display: flex;
  height: 100%;
  width: 80%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.title-container-additional-footer {
  flex: 1 1;
  display: flex;
  align-items: center;
  height: 20px;
  width: 100%;
  justify-content: center;
  padding-top: 35px;
}

.title-additional-footer {
  color:antiquewhite;
  font-family: MontSerrat;
  font-size: 12px;
  text-transform: uppercase;
}

.bottom-links-container-additional-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-top: 22px;
}

.link-additional-footer {
  margin-left: 12px;
  color: antiquewhite;
  font-family: MontSerrat;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
}

.link-additional-footer a {
  text-decoration: none;
}

.bottom-links-container-additional-footer a {
  text-decoration: none;
}

.link-additional-footer:hover {
  transition: all 0.1s ease-out;
  color: #0063c8;
}

/* media queries */

@media (max-width: 850px) {
  .link-additional-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
}

@media (max-width: 800px) {
  .bottom-links-container-additional-footer {
    display: block;
  }
}
@media (max-width: 500px) {
  .bottom-links-container-additional-footer a:last-child {
    width: 100%;
}
  .link-additional-footer {
    margin: 0;
  }
}