.button-div-header-fix a{
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 13px;
  text-transform: uppercase;
  color: #9a9a9a;
  cursor: pointer;
  font-weight: 700;
  height: 98px;
  letter-spacing: 1px;
}

.logo-subcontainer-header {
  padding-right: 50px;
  cursor: pointer;
}

.logo-subcontainer-header img {
  width: 200px;
}

.button-div-header-fix a:hover {
  transition: all 0.2s ease-out;
  color: #006CAA;
}

.main-div-container-header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #f9f6f6;
}

.sub-div-main-container-header {
  display: flex;
  width: 80%;
}

@media (max-width: 1200px) {
  .sub-div-main-container-header {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-logo-container-header {
  display: flex;
  align-items: center;
}

.button-div-header-fix {
  display: flex;
  align-items: center;
}

.main-div-button-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

/* Dropdown Button */
.dropbtn {
  font-family: 'Montserrat';
  display: flex;
  cursor: pointer;
  transition: all 0.2s ease-out;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  height: 101px;
  align-items: center;
  position: relative;
  text-decoration: none;
  color: #9a9a9a;
  transition: color 0.2s ease-out;
}

.dropbtn:hover {
  color: #0662C5;
}

.underline-header {
  position: absolute;
  height: 3px;
  background-color: #0662C5;
  bottom: 0;
  width: 0;
  transition: width 0.2s;
}

.dropbtn:hover > .underline-header {
  width: 100%;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  max-height: 0;
  position: absolute;
  background-color: #262626;
  transition: all 0.3s;
  width: 263px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  overflow: hidden;
  border-radius: 0px 0px 5px 5px;
}

.dropdown-content a {
  text-decoration: none;
  color:#9a9a9a
}


/* Links inside the dropdown */
.dropdown-content div {
  color: #9a9a9a;
  cursor: pointer;
  transition: all 0.2s ease-out;
  font-size: 13px;
  text-transform: uppercase;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
}

/* Change color of dropdown links on hover */
.dropdown-content div:hover {
  color: #ffffff;
}

/* Show the dropdown menu on hover */
.show-dropdown-content {
  max-height: 1000px;
  position: absolute;
}


