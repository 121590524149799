.main-container-user-address-displayer-displayer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-user-address-displayer-displayer {
  width: 80%;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.container-address-user-address-displayer {
  padding: 20px;
  border: 1px solid #e0e0e0;
}

.title-container-address-user-address-displayer {
  font-family: 'Montserrat';
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  color: #333333;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.container-edit-button-user-address-displayer {
  font-size: 15px;
  text-transform: capitalize;
  color: #999999;
  font-weight: 300;
  display: flex;
}

.container-edit-button-user-address-displayer a {
  text-decoration: none;
  color: #999999;
}

.container-edit-button-user-address-displayer :hover {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #0662c5;
}

.content-container-address-user-address-displayer {
  font-family: 'Montserrat';
  color: #777777;
  margin-bottom: 10px;
  font-size: 17px;
}

.content-container-address-user-address-displayer svg {
  margin-right: 5px;
}

@media (max-width: 500px) {
  .title-container-address-user-address-displayer {
    font-size: 13px;
  }

  .sub-container-user-address-displayer {
    width: 90%;
  }

  .content-container-address-user-address-displayer {
    font-size: 15px;
  }
}

@media (max-width: 300px) {
  .sub-container-content-user-downloads div {
    font-size: 12px;
  }
}
