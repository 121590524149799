.main-container-order-displayer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.title-order-displayer {
  display: flex;
  font-family: 'MontSerrat';
  font-size: 17px;
  color: #777777;
  padding: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.container-circular-progress-order-displayer {
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-order-displayer div:first-child {
  display: flex;
  align-items: center;
}

.title-order-displayer svg {
  padding-right: 5px;
}

.title-highlight-order-displayer {
  color: black;
  background-color: #fcf8e3;
}

.details-title-order-displayer {
  color: #333333;
  font-weight: 700;
  font-family: 'MontSerrat';
  font-size: 18px;
  text-transform: uppercase;
  padding: 16px;
}

.container-first-table-order-displayer {
  padding: 16px;
  display: flex;
}

.container-first-table-order-displayer > div:nth-of-type(1) {
  border-left: 1px solid #e0e0e0;
  border-radius: 5px;
}

.container-first-table-order-displayer > div:nth-of-type(2) {
  border-right: 1px solid #e0e0e0;
  border-radius: 5px;
}

.sub-container-first-table-order-displayer {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}

.column-name-first-table-order-displayer {
  width: 100%;
  color: #333;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-collapse: collapse;
}

.sub-container-second-table-order-displayer {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}

.sub-container-second-table-order-displayer > .content-second-table-order-displayer:first-child {
  border-top: 1px solid #e0e0e0;
}

.link-order-displayer a {
  transition: ease-in-out 0.2s all;
  color: #999999;
  text-decoration: none;
}

.link-order-displayer a:hover {
  transition: ease-in-out 0.2s all;
  color: #1976de;
}

.content-second-table-order-displayer {
  width: 100%;
  color: #333;
  font-size: 16px;
  font-family: 'Montserrat';
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
  padding: 10px;
  border-collapse: collapse;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.document-table-order-displayer {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 16px;
}

.document-table-order-displayer > div {
  transition: ease-in-out 0.2s all; 
  font-family: 'Montserrat';
  color: #333333;
  padding: 8px;
}

.document-table-order-displayer a {
  transition: ease-in-out 0.2s all; 
  text-decoration: none;
  color: #333333;
}

.document-table-order-displayer a:hover {
  transition: ease-in-out 0.2s all; 
  color:#1976de;
  cursor: pointer;
}

.container-address-displayer-order-displayer {
  padding: 16px;
}

@media screen and (max-width: 400px) {
  .content-second-table-order-displayer {
    height: 60px;
  }
}
