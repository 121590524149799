.main-container-admin-user-view {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-admin-user-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.progress-admin-user-view {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-admin-user-view {
  font-family: 'Montserrat';
  font-size: 18px;
  text-transform: uppercase;
  color: #333333;
  font-weight: 700;
  width: 100%;
  text-align: center;
}

.subtitle-admin-user-view {
  font-family: 'Montserrat';
  margin-bottom: 20px;
  font-size: 16px;
  color: #777777;
  width: 100%;
  text-align: center;
}

.container-address-admin-user-view {
  padding: 16px;
  margin: 16px;
  max-width: 600px;
  border: 1px solid #e0e0e0;
}

.title-container-address-admin-user-view {
  font-family: 'Montserrat';
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  color: #333333;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.container-edit-button-admin-user-view {
  font-size: 15px;
  text-transform: capitalize;
  color: #999999;
  font-weight: 300;
  display: flex;
}

.container-edit-button-admin-user-view a {
  text-decoration: none;
  color: #999999;
}

.container-edit-button-admin-user-view :hover {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #0662c5;
}

.content-container-address-admin-user-view {
  font-family: 'Montserrat';
  color: #777777;
  font-size: 17px;
  padding: 8px;
}

.content-container-address-admin-user-view svg {
  margin-right: 5px;
}

.edit-button-admin-user-view {
  display: flex;
  padding-left: 50px;
}

@media (max-width: 500px) {
  .title-admin-user-view {
    font-size: 15px;
  }

  .title-container-address-admin-user-view {
    font-size: 13px;
  }

  .sub-container-admin-user-view {
    width: 90%;
  }

  .content-container-address-admin-user-view {
    font-size: 15px;
  }
}
