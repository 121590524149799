.main-container-admin-resume {
  display: flex;
  flex-wrap: wrap;
}

.sub-container-admin-resume {
  width: 100%;
  margin: 16px;
  box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.header-admin-resume {
  display: flex;
  padding: 16px;
  align-items: center;
  width: 100%;
}

.header-admin-resume > div {
  width: 35px;
  height: 35px;
  background-color: #1976d2;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: antiquewhite;
  font-size: 20px;
  font-weight: 700;
}

.header-admin-resume p {
  font-family: 'Montserrat';
  color: #9a9a9a;
  font-weight: 500;
  margin: 0;
  padding-left: 20px;
}

.divider-admin-resume {
  height: 100px;
  width: 5px;
  border-radius: 10px;
  background-color: lightgray;
}

.content-container-admin-resume {
  background: #e0e0e0;
  border-radius: 2px;
  min-height: 150px;
  flex-wrap: wrap;
  padding: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.info-container-admin-resume {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  color: #9E9E9E;
}

.info-container-admin-resume span {
  color: #1976d2 !important;
  font-weight: 800;
  font-size: 20px !important;
}

.divider-admin-resume:last-child {
  display: none;
}

.container-accordion-admin-resume {
  width: 100%;
  padding: 16px;
  margin-bottom: 200px;
}

.container-accordion-admin-resume table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.container-accordion-admin-resume table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.container-accordion-admin-resume table tr {
  border: 1px solid #ddd;
  padding: 0.35em;
}

.container-accordion-admin-resume table th,
.container-accordion-admin-resume table td {
  padding: 0.625em;
  text-align: center;
}

.container-accordion-admin-resume table td {
  color: #999999;
  font-family: 'MontSerrat';
  font-size: 15px;
  font-weight: 500;
}

.container-accordion-admin-resume table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: 'Montserrat';
  color: #555;
}

.container-accordion-admin-resume a {
  transition: all 0.2 ease-in-out;
  text-decoration: none;
  color: #999999;
}

.container-accordion-admin-resume a:hover {
  transition: all 0.2 ease-in-out;
  color: #1976d2;
}


@media (max-width: 1100px) {
  .info-container-admin-resume {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid;
    padding-bottom: 16px;
    padding-top: 16px;
  }

  .info-container-admin-resume:last-child {
    border-bottom: none;
  }

  .divider-admin-resume {
    display: none !important;
  }
}