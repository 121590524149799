.main-container-user-board {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.sub-container-user-board {
  padding: 16px;
}

.sub-container-content-user-board {
  display: flex;
  padding: 16px;
  font-family: 'MontSerrat';
  font-size: 16px;
  color: #777777;
  flex-wrap: wrap;
}

.sub-container-content-user-board p {
  margin: 0;
}

.container-hello-user-board {
  display: flex;
}

.user-email-user-board {
  font-weight: 600;
}

.logout-button-user-board:hover {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  color: #0662c5;
}

.content-user-board {
  margin: 0px;
  color: #777777;
  font-family: 'MontSerrat';
  font-size: 16px;
  padding: 16px;
  line-height: 1.5;
}

@media (max-width: 400px) {
  .sub-container-content-user-board {
    font-size: 15px;
  }
}
