/* MAIN CONTAINER */

.main-container-home {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sub-container-home {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* TOP BANNER */

.container-banner-top-home {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.container-banner-top-home h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333333;
}

.container-banner-top-home svg {
  font-size: 35px;
  color: #006caa;
  padding: 20px;
}

.subtitle-banner-top-home {
  width: 80%;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
}

.subtitle-banner-top-home h4 {
  font-family: 'Montserrat';
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
}

/* MIDDLE BANNER */

.container-banner-middle-home {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px 0px 50px 0px;
}

.container-banner-middle-home svg {
  font-size: 32px;
  color: #e7e7e7;
}

.background-lock-icon-home {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #5472d2;
}

.title-banner-middle-home {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 25px;
  text-transform: uppercase;
  color: #333333;
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
}

.subtitle-banner-middle-home {
  width: 100%;
  font-weight: 700;
  line-height: 1.5;
  color: #333;
  font-family: 'Montserrat';
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
}

.content-banner-middle-home {
  width: 100%;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  color: #777777;
  text-align: center;
  margin: 20px 0px 0px 0px;
}

.container-banner-middle-home p {
  margin: 0;
}

.container-banner-middle-home a {
  text-decoration: none;
}

/* CONTAINER CARDS BOTTOM  */

.main-container-cards-bottom-home {
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.subcontainer-cards-bottom-home {
  width: 100%;
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
}

.title-cards-bottom-home {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-cards-bottom-home svg {
  font-size: 30px;
  color: #5472d2;
}

.title-cards-bottom-home h3 {
  font-family: 'Montserrat', cursive, sans-serif;
  font-weight: 700;
  line-height: 1.5;
  color: #333;
  text-transform: uppercase;
  font-size: 16px;
  margin-left: 15px;
}

.logo-cards-bottom-home {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.logo-cards-bottom-home img {
  width: 250px;
}

.subtitle-cards-bottom-home {
  width: 100%;
  font-size: 16px;
  font-family: 'Montserrat';
  text-transform: uppercase;
  color: #777777;
  font-weight: 700;
}

.subtitle-cards-bottom-home p {
  padding-top: 20px;
  text-align: center;
}

.content-container-cards-bottom-home {
  width: 100%;
  font-size: 16px;
  font-family: 'Montserrat';
  color: #777777;
  font-weight: 500;
}

.content-cards-bottom-home {
  padding: 16px;
  text-align: center;
}

.main-container-cards-bottom-home a {
  width: 100%;
  text-decoration: none;
}

/* BOTTOM BANNER */

.container-qualiopi-banner-home {
  width: 100%;
  padding-top: 100px;
}

.qualiopi-banner-home {
  display: flex;
  justify-content: center;
}

.qualiopi-banner-home svg {
  font-size: 30px;
  color: #5472d2;
}

.qualiopi-banner-home div {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  margin-left: 10px;
}

.image-banner-qualiopi-home {
  text-align: center;
}

.handicap-disclaimer {
  width: 80%;
  margin-top: 78px;
  text-align: center;
  font-family: 'Montserrat';
  color: #797979;
}

.handicap-disclaimer span {
  font-weight: 600;
}

.image-banner-qualiopi-home img {
  width: 100%;
  max-width: 500px;
  padding-top: 50px;
}

.bottom-title-qualiopi-home {
  text-align: center;
  font-weight: 800;
  font-family: 'Montserrat';
  font-size: 16px;
  color: #797979;
  margin: 20px 0px 50px 0px;
}

.bottom-container-content-qualiopi-home {
  display: flex;
  justify-content: center;
}

.bottom-container-content-qualiopi-home > div {
  width: 90%;
}

.bottom-content-qualiopi-home {
  font-family: 'Montserrat';
  font-size: 16px;
  text-align: center;
  color: #797979;
  font-weight: 500;
}

.bottom-title-qualiopi-home p {
  text-align: center;
}

.bottom-qualiopi-home {
  padding-top: 50px;
}

/* BUTTONS */

.button-redirect-formations-home {
  display: flex;
  width: 100%;
  max-height: 45px;
  justify-content: center;
  margin-top: 20px;
}

/* MEDIA QUERIES */

@media (max-width: 800px) {
  .subcontainer-cards-bottom-home {
    padding-top: 50px;
  }
}

@media (max-width: 700px) {
  .subtitle-banner-top-home h4 {
    font-size: 14px;
    text-align: center;
  }
}
