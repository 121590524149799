.sub-container-user-downloads {
  width: 80%;
  padding: 50px 0px 50px 0px;
}

.content-container-user-downloads {
  height: 100px;
  background-color: #f5f5f5;
  border-top: 4px solid #1e85be;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-container-content-user-downloads {
  display: flex;
}

.sub-container-content-user-downloads div {
  margin-left: 10px;
  color: #333;
  font-family: 'MontSerrat';
  font-size: 16px;
}

.sub-container-content-user-downloads svg {
  color: #1e85be;
}

@media (max-width: 500px) {
  .sub-container-content-user-downloads div {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .sub-container-content-user-downloads div {
    font-size: 11px;
  }
}
