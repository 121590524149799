.main-container-contact {
  width: 100%;
}

/* TOP BANNER */

.container-banner-contact {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.container-banner-contact h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333333;
}

.container-banner-contact svg {
  font-size: 35px;
  color: #006caa;
  padding: 20px;
}

.subtitle-banner-contact {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
}

.subtitle-banner-contact h4 {
  font-family: 'Montserrat';
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
}

.main-content-container-contact {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-content-container-contact {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 0px 100px 0px;
}

.contact-form-container-contact  {
  width: 60%;
}

.title-content-contact {
  font-size: 16px;
  font-weight: 500;
  font-family: 'MONTSERRAT';
  text-transform: uppercase;
  letter-spacing: 1.3px;
  margin: 0 0 25px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.phone-address-container-contact p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.625;
  color: #777777;
}

.address-title-contact {
  padding-top: 40px;
}

.container-title-contact h3 {
  font-size: 16px;
  font-family: 'Montserrat';
  color: #999999;
}

.textfield-contact {
  padding: 15px 0px 15px 0px;
}

.textfield-contact label {
  text-transform: capitalize;
}

.textarea-contact {
  padding-top: 15px;
}

.textarea-contact textarea {
  border-color: #c4c4c4;
  height: 200px !important;
  width: 100%;
  font-size: 16px;
  font-family: 'Montserrat';
}

.button-contact-page {
  display: flex;
  padding: 50px 0px 50px 0px;
  width: 100%;
}

.social-network-icons-container-contact {
  font-size: 37px;
  display: flex;
  font-size: 37px;
  display: flex;
  margin-top: 45px;
  justify-content: space-evenly;
}

.facebook-icon-contact a {
  color: #4867aa;
}

.facebook-icon-contact svg {
  cursor: pointer;
}

.linkedin-icon-contact svg {
  cursor: pointer;
}

.linkedin-icon-contact a {
  color: #4995cd;
  padding-left: 10px;
}

@media (max-width: 1300px) {
  .contact-form-container-contact  {
    max-width: 600px !important;
  }
}

@media (max-width: 1000px) {
  .title-banner-contact {
    font-size: 42px;
  }
}

@media (max-width: 800px) {
  .title-banner-contact {
    font-size: 32px;
  }
}

@media (max-width: 500px) {
  .sub-content-container-contact {
    padding-top: 50px;
  }
  .title-banner-contact {
    font-size: 18px;
  }

  .banner-container-contact {
    height: 178px;
  }

  .phone-address-container-contact {
    width: 100%;
  }

  .title-content-contact {
    display: flex;
    justify-content: center;
  }

  .phone-address-container-contact p {
    display: flex;
    justify-content: center;
  }

  .button-contact-page {
    justify-content: center;
  }

  .container-title-contact h3 {
    text-align: center;
  }

  .social-network-icons-container-contact {
    display: none;
  }

  .MuiFormControl-root {
    width: 100%;
  }
}
