.main-container-admin-formation-view {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-admin-formation-view {
  max-width: 80%;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 25%);
  margin: 16px;
  padding: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.container-left-admin-formation-view {
  padding-left: 50px;
  padding-right: 25px;
  max-width: 325px;
}

.content-admin-formation-view {
  padding: 8px 8px 8px 0px;
}

.container-right-admin-formation-view {
  flex: 1;
  padding-right: 50px;
  padding-left: 25px;
}

.container-trainers-name-admin-formation-view,
.container-trainers-prof-admin-formation-view {
  flex-wrap: wrap;
  padding: 8px 8px 8px 0px;
  margin: 0;
}

.food-and-shelter-not-included {
  font-size: 14px !important;
}

.margin-bottom-admin-formation-view {
  margin-bottom: 20px;
}

.duration-admin-formation-view {
  text-transform: initial !important;
}

.container-left-admin-formation-view img {
  width: 300px;
}

.container-left-admin-formation-view h3 {
  font-family: Montserrat;
  text-transform: initial;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  padding-bottom: 8px;
  color: #006caa;
  margin: 0px;
}

.container-left-admin-formation-view h4 {
  font-family: Montserrat;
  font-weight: 600;
  margin: 0;
  padding: 8px 8px 8px 0px;
  font-size: 16px;
  line-height: 29px;
  color: #006caa;
}

.active-select-admin-formation-view {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container-left-admin-formation-view h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 8px;
  margin: 0px;
  color: #878787;
}

.colored-content-admin-formation-view div {
  color: #006caa !important;
}

.colored-content-admin-formation-view svg {
  color: #006caa !important;
}

.container-left-admin-formation-view div {
  width: 100%;
  display: flex;
  font-family: Montserrat;
  font-size: 16px;
  margin: 0;
  color: #767676;
}

.container-left-admin-formation-view svg {
  margin-right: 10px;
}

.container-right-admin-formation-view h4 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  line-height: 29px;
  color: #006caa;
}

.container-right-admin-formation-view div {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 25px;
  padding: 8px;
  color: #767676;
}

.pdf-icon-link-admin-formation-view {
  display: flex;
  align-items: center;
  margin: 0px !important;
}

.pdf-icon-link-admin-formation-view div {
  display: flex;
  align-items: center;
}

.pdf-icon-link-admin-formation-view a {
  text-decoration: none;
  display: flex;
}

.pdf-icon-link-admin-formation-view div:hover {
  transition: ease-in-out 0.2s all;
  color: #006caa;
}

.pdf-icon-link-admin-formation-view svg {
  font-family: Montserrat;
  font-size: 29px;
  line-height: 25px;
  color: #767676;
  margin-right: 10px;
  margin: 0px 10px 0px 0px !important;
}

.pdf-icon-link-admin-formation-view div {
  margin: 0px !important;
}

.container-button-trigger-email {
  width: 100%;
  display: flex;
  padding: 16px;
  justify-content: center;
}

.sub-container-admin-formation-view a {
  text-decoration: none;
}

@media (max-width: 1100px) {
  .sub-container-admin-formation-view {
    max-width: 80%;
  }
}

@media (max-width: 900px) {
  .sub-container-admin-formation-view {
    max-width: 90%;
  }
}

@media (max-width: 800px) {
  .container-left-admin-formation-view {
    padding: 0px;
  }

  .container-right-admin-formation-view {
    min-width: 300px;
    padding-right: 0px;
    padding-left: 20px;
  }
}

@media (max-width: 500px) {
  .sub-container-admin-formation-view {
    width: 100%;
  }
  .container-right-admin-formation-view {
    padding-left: 0px;
  }
}
