.main-container-order-sepa-payment {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-order-sepa-payment {
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
}

.content-order-sepa-payment span {
  font-weight: 700;
}

.general-terms-container-sepa-element {
  display: flex;
  align-items: center;
  padding: 16px;
}

.general-terms-container-sepa-element > div {
  width: 100%;
  display: flex;
}

.general-terms-container-sepa-element > div > div {
  font-family: Montserrat;
  font-size: 16px;
}

.general-terms-container-sepa-element a {
  transition: ease-in-out 0.2s all;
  text-decoration: none;
  color: #969696;
  padding-left: 6px;
}

.general-terms-container-sepa-element a:hover {
  transition: ease-in-out 0.2s all;
  color: black;
}

.error-checkbox-sepa-element {
  color: red;
}

.error-checkbox-sepa-element a {
  color: red;
}

.container-alert-sepa-element {
  padding: 16px;
}

.container-button-sepa-element {
  text-align: center;
  padding: 16px;
}

.notice-span-sepa-payment {
  font-style: italic;
  font-weight: 600;
}

.privacy-policy-sepa-payment a {
  transition: ease-in-out 0.2s all;
  text-decoration: none;
  color: #969696;
  padding-left: 5px;
}

.privacy-policy-sepa-payment a:hover {
  transition: ease-in-out 0.2s all;
  color: black;
}
