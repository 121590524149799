.main-container-user-orders {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 300px;
  align-items: center;
}

.sub-container-user-orders {
  display: flex;
  padding: 16px;
  justify-content: center;
}

.container-circularprogress-user-orders {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.sub-container-user-orders a {
  text-decoration: none;
  transition: ease-in-out 0.2s all;
  text-decoration: none;
  color: #999999;
}

.sub-container-user-orders a:hover {
  transition: ease-in-out 0.2s all;
  color: #1976de;
}

.sub-container-user-orders table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.sub-container-user-orders table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.sub-container-user-orders table tr {
  border: 1px solid #ddd;
  padding: 0.35em;
}

.sub-container-user-orders table th,
.sub-container-user-orders table td {
  padding: 0.625em;
  text-align: center;
}

.sub-container-user-orders table td {
  color: #999999;
  font-family: 'MontSerrat';
  font-size: 15px;
  font-weight: 500;
}

.sub-container-user-orders table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: 'Montserrat';
  color: #555;
}

.button-table-user-orders {
  display: flex;
  justify-content: center;
  height: 25px;
}

@media screen and (max-width: 700px) {
  .sub-container-user-orders table {
    border: 0;
  }

  .sub-container-user-orders table caption {
    font-size: 1.3em;
  }

  .sub-container-user-orders table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .sub-container-user-orders table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .sub-container-user-orders table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .sub-container-user-orders table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a .sub-container-user-orders table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .sub-container-user-orders table td:last-child {
    border-bottom: 0;
    height: 16px;
  }

  .button-table-user-orders {
    justify-content: flex-end !important;
  }
}
