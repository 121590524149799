.main-container-order-login-register {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.sub-container-order-login-register {
  width: 100%;
  padding: 50px 0px 100px 0px;
  display: flex;
  justify-content: center;
}


@media (max-width: 750px) {
.sub-container-order-login-register {
    flex-wrap: wrap;
}
}