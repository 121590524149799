.main-container-trainers {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sub-container-trainer {
  max-width: 995px;
  margin-bottom: 24px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

/* TOP BANNER */

.container-banner-trainers {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.container-banner-trainers h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333333;
}

.container-banner-trainers svg {
  font-size: 35px;
  color: #006caa;
  padding: 20px;
}

.subtitle-banner-trainers {
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
  text-align: center;
  width: 80%;
}

.subtitle-banner-trainers h4 {
  font-family: 'Montserrat';
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
}

.container-card-trainer {
  width: 220px;
  background: #ffffff;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 5px;
  margin: 8px;
}

.container-card-trainer img {
  object-fit: cover;
  object-position: top;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  width: 100%;
  border-radius: 5px 5px 0px 0px;
  height: 250px;
}

.container-card-trainer h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 10px 20px 10px;
}

.container-card-trainer h3:hover {
  cursor: pointer;
  color: #006caa;
}

.container-card-trainer h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #878787;
  margin: 0px 0px 15px 20px;
}

.container-content-card-trainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  border-bottom: 1px solid #e0e0e0;
}

.container-content-card-trainer {
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 30px;
  flex-wrap: wrap;
}

.container-card-trainer a {
  text-decoration: none;
}

.container-content-card-trainer svg {
  transition: ease-in-out 0.2s all;
  cursor: pointer;
  color: #747274;
}

.container-content-card-trainer svg:hover {
  color: #006caa;
}

.chevron-rotate-card-trainer svg {
  transition: ease-in-out 0.2s all;
  transform: rotate(180deg);
}

.hidden-span-card-trainer {
  justify-content: center;
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;
}

.hidden-span-card-trainer p {
  font-family: 'Montserrat';
  font-size: 14px;
  text-align: center;
}

.hidden-span-card-trainer.show-content-card-trainer {
  max-height: 200px;
}

.redirect-button-span-card-trainer {
  margin: 30px !important;
  text-align: center;
  justify-content: center;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 13px;
  color: #006caa;
  text-transform: uppercase;
}

.redirect-button-span-card-trainer:hover {
  cursor: pointer;
  color: #333333;
}
