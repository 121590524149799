.main-container-faq {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.container-top-info-faq {
  width: 80%;
  height: 60px;
  color: #5e7f96;
  border-color: 1px solid #cfebfe;
  border: 1px solid #cfebfe;
  background-color: #dff2fe;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.container-top-info-faq svg {
  padding: 30px;
  font-size: 25px;
  color: #56b0ee;
}

.container-top-info-faq div {
  font-family: 'Montserrat';
}

.accordion-container-faq {
  width: 80%;
  padding: 50px 0px 50px 0px;
}
/* TOP BANNER */

.container-banner-faq {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.container-banner-faq h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333333;
}

.container-banner-faq svg {
  font-size: 35px;
  color: #006caa;
  padding: 20px;
}

.subtitle-banner-faq {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
  text-align: center;
}

.subtitle-banner-faq h4 {
  font-family: 'Montserrat';
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
}

.hidden-content-faq {
  line-height: 33px;
}

.sub-container-faq {
  width: 80%;
}

@media (max-width: 800px) {
  .container-top-info-faq {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .container-top-info-faq {
    font-size: 14px;
  }

  .hidden-content-faq {
    font-size: 14px;
  }
}
