.breadcrumbs-container-formations {
  width: 100%;
  padding-left: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
}

.breadcrumbs-container-formations a {
  text-decoration: none;
  color: #777777;    
  font-family: 'Montserrat';
}

/* TOP BANNER */ 

.container-banner-formations {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.container-banner-formations h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333333;
}

.container-banner-formations svg {
  font-size: 35px;
  color: #006caa;
  padding: 20px;
} 

.subtitle-banner-formations {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
  text-align: center;
}  

.subtitle-banner-formations h4 {
  font-family: 'Montserrat';
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
}



/* CONTAINER / CONTENT */

.main-container-content-formations {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-content-formations {
  width: 90%;
  display: flex;
  justify-content: center;    
  padding-bottom: 50px;
}

/* media queries */

@media (max-width: 1000px) {
  .title-banner-formations {
    font-size: 42px;
  }
}

@media (max-width: 800px) {
  .title-banner-formations {
    font-size: 32px;
  }
} 

@media (max-width: 500px) {
  .title-banner-formations {
    font-size: 22px;
  }

  .banner-container-formations {
    height: 178px;
  }
}