/* MAIN CONTAINER HOME*/


.main-container-banner-counter {
  width: 100%;
  height: 430px;
  background-image: url(../../images/iffco-counter-home.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
} 

/* COUNTER CARD BANNER  */

.counter-card-banner {
  text-align: center;
}

.counter-card-banner svg {
  font-size: 70px;
  color: #0662c5;
  width: 100%;
}

.counter-counter-banner {
  font-size: 52px;
  color: white;
  font-family: 'Montserrat';
  font-weight: 600;
  text-align: center;
}

.subtitle-counter-banner {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  text-align: center;
}

.first-counter-card-banner {
  border-right: 1px solid #6697c2;
  padding-right: 100px;
}

.second-counter-card-banner {
  padding: 0px 100px 0px 100px;
  border-right: 1px solid #6697c2;
}

.third-counter-card-banner {
  padding-left: 100px;
}

/* media queries */

@media (max-width: 1100px) {
  .counter-card-banner svg {
    font-size: 50px;
}
  .counter-counter-banner {
    font-size: 42px;
  }

  .subtitle-counter-banner {
    font-size: 14px;
  }

  .first-counter-card-banner {
    padding-right: 50px;
  }

  .second-counter-card-banner {
    padding: 0px 50px 0px 50px;
  }

  .third-counter-card-banner {
    padding-left: 50px;
}
}

@media (max-width: 800px) {
  .counter-card-banner svg {
    font-size: 40px;
}
  .counter-counter-banner {
    font-size: 32px;
  }

  .subtitle-counter-banner {
    font-size: 12px;
  }
}

@media (max-width: 650px) {
  .main-container-banner-counter {
    flex-wrap: wrap;
  }

  .counter-card-banner {
    width: 100%;
  }

  .first-counter-card-banner {
    padding: 0px;
  }

  .second-counter-card-banner {
    padding: 0px;
  }

  .third-counter-card-banner {
    padding: 0px;
}
}



