.MuiTabs-flexContainer {
  background-color: white !important;
}

.MuiAppBar-colorDefault {
  box-shadow: none !important;
}

.MuiTabs-flexContainer {
  justify-content: center !important;
}

.title-order-payment-tabs {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  color: #464646;
  padding: 16px;
  margin: 0;
}
