.main-container-support {
  width: 100%;
}

/* TOP BANNER */

.container-banner-support {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.container-banner-support h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333333;
}

.container-banner-support svg {
  font-size: 35px;
  color: #006caa;
  padding: 20px;
}

.subtitle-banner-support {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.subtitle-banner-support h4 {
  font-family: 'Montserrat';
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
  width: 80%;
}

.main-content-support {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-container-support {
  width: 80%;
}

.titles-support {
  font-size: 34px;
  font-style: MontSerrat;
  font-weight: 700;
  color: #333;
}

.first-content-support {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
  color: #777777;
}

.second-content-support {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 600;
  color: #777777;
  list-style: none;
}

.list-content-support {
  font-family: MontSerrat;
  font-weight: 500;
  margin-left: 10px;
  line-height: 30px;
}

.list-content-support ul {
  list-style: none;
}

.container-button-support {
  text-align: center;
  padding: 50px;
}

.container-button-support a {
  text-decoration: none;
}

/* media queries */

@media (max-width: 1000px) {
  .title-banner-support {
    font-size: 42px;
  }

  .titles-support {
    font-size: 30px;
  }
}

@media (max-width: 800px) {
  .title-banner-support {
    font-size: 32px;
  }

  .titles-support {
    font-size: 22px;
  }

  .banner-container-support {
    height: 178px;
  }

  .sub-container-support {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .title-banner-support {
    font-size: 22px;
  }

  .titles-support {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .titles-support {
    font-size: 14px;
  }

  .first-content-support p {
    font-size: 13px;
  }

  .list-content-support {
    font-size: 13px;
  }

  .second-content-support p {
    font-size: 13px;
  }
}
