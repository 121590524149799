.main-container-admin-trainers {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-container-admin-trainers {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
  background-color: #ddeffd !important;
}

.makeStyles-highlight-38 {
color: #0066b3 !important;
}

.MuiTableCell-paddingNone a {
  text-decoration: none !important; 
  color: #333333;
  text-transform: capitalize;
}

.MuiTableCell-paddingNone a:hover {
  color: #0066b3 !important
}

.MuiTableCell-alignRight {
  text-align: left !important;
  flex-direction: row-reverse;
  text-transform: capitalize;
}

.makeStyles-root-1 {
  width: auto !important;
}

.MuiTableCell-head {
  font-weight: 600 !important;
}

.loading-container-admin-trainers {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.MuiAlert-standardSuccess {
  color: white !important;
  background-color: #4caf50 !important;
}
.MuiAlert-standardSuccess .MuiAlert-icon {
  color: white !important;
}

.MuiAlert-standardError {
  color: white !important;
  background-color: #f44336 !important;
}

.MuiAlert-standardError .MuiAlert-icon {
    color: white !important;
}

.makeStyles-paper-2 {
  margin: 0 !important;
}