.main-container-images-page {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sub-container-images-page {
  width: 90%;
  padding: 0px 0px 100px 0px;
}

/* TOP BANNER */ 

.container-banner-images-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.container-banner-images-page h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333333;
}

.container-banner-images-page svg {
  font-size: 35px;
  color: #006caa;
  padding: 20px;
} 

.subtitle-banner-images-page {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}  

.subtitle-banner-images-page h4 {
  font-family: 'Montserrat';
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
  width: 80%;
}

.images-container-images-page {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.images-container-images-page img {
  border-radius: 10px;
  object-fit: cover;
}

.images-container-images-page img:hover {
  cursor: pointer;
  transition: ease-in-out 0.2s all;
  opacity: 0.8;
}


/* media queries */

@media (max-width: 1000px) {
  .title-banner-images-page {
    font-size: 42px;
  }
}

@media (max-width: 800px) {
  .title-banner-images-page {
    font-size: 32px;
  }
} 

@media (max-width: 500px) {
  .title-banner-images-page {
    font-size: 22px;
  }

  .images-container-images-page img {
    width: 250px;
    height: 250px;
  }

  .banner-container-images-page {
    height: 178px;
  }
}