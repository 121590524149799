.title-menu-admin {
  color: antiquewhite;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 500;
  padding-left: 15px;
}

.main-container-admin-menu svg {
  color: antiquewhite;
}

.MuiMenuItem-root {
  text-transform: capitalize !important;
}
