.picture-displayer-main-container-footer {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.main-container-pic-displayer-footer {
  display: flex;
  flex-wrap: wrap;
}

.first-line-pic-container-footer {
  display: flex;
  max-width: 312px;
  flex-wrap: wrap;
}

.second-line-pic-container-footer {
  display: flex;
}

.images-first-line-container-pic-displayer {
  margin: 0px 0px 15px 15px;
}

.images-first-line-container-pic-displayer img {
  cursor: pointer;
  object-fit: cover;
}