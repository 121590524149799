
.partners-logo-container {
  display: flex;
  flex-wrap:wrap;
}

.main-container-partners a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.partners-logo-container img {
  width: 100%;
  max-width: 300px;
  margin-top: 20px;
}

.kt-tape-logo,
.chair-et-os-logo {
  max-width: 180px !important;
} 

.medsmart-logo {
  margin-top: 40px;
}

.bemer-logo {
  margin-top: 60px;
}

.franco-fils-logo {
  margin-top: 25px;
}

.left-container,
.right-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 400px;
}