.main-container-trainings-displayer {
  display: flex;
  justify-content: center;
  max-width: 1060px;
  flex-wrap: wrap;
}

.container-trainings-displayer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container-trainings-displayer a {
  text-decoration: none;
}

.text-overflow-trainings-diplayer > div {
  margin-bottom: 5px;
}

.text-overflow-trainings-diplayer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
}

.sub-container-trainings-displayer {
  width: 315px;
  background: #ffffff;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 0px 9px 18px 9px;
  display: flex;
  flex-direction: column;
}

.sub-container-trainings-displayer img {
  width: 100%;
  border-radius: 5px 5px 0px 0px;
  max-height: 190px;
  height: 190px;
  object-fit: cover;
}

.container-titles-trainings-displayer {
  min-height: 72px;
  border-bottom: 1px solid #e1dddd;
}

.container-titles-trainings-displayer a {
  text-decoration: none;
}

.container-titles-trainings-displayer h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 10px 20px 10px;
  text-transform: initial;
}

.container-titles-trainings-displayer h3:hover {
  cursor: pointer;
  transition: ease-in-out 0.2s all;
  color: #006caa;
}

.container-titles-trainings-displayer h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #878787;
  margin: 0px 0px 15px 20px;
}

.content-trainings-displayer {
  flex: 1;
}

.content-trainings-displayer > div {
  display: flex;
  margin: 25px 0px 0px 20px;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  font-family: Montserrat;
}

.content-trainings-displayer > div > svg {
  margin-right: 10px;
  font-size: 18px;
  color: #878787;
}

.content-trainings-displayer > div > div {
  font-family: Montserrat;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  width: 100%;
}

.colored-content-trainings-displayer svg {
  color: #006caa !important;
}

.colored-content-trainings-displayer div {
  color: #006caa !important;
}

.colored-content-trainings-displayer div:hover {
  cursor: pointer;
}

.button-trainings-displayer {
  margin: 30px !important;
  text-align: center;
  justify-content: center;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  color: #006caa;
  text-transform: uppercase;
}

.button-trainings-displayer:hover {
  cursor: pointer;
  transition: ease-in-out 0.2s all;
  color: #333333;
}

.bottom-text-trainings-displayer {
  font-size: 16px;
  font-family: 'Montserrat';
  color: #777777;
  font-weight: 500;
  margin: 0px;
  text-align: center;
  padding: 32px;
  line-height: 1.625;
  padding-top: 100px;
}

.loading-container-trainings-displayer {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 700px) {
  .sub-container-trainings-displayer {
    width: 80%;
    height: auto;
  }

  .sub-container-trainings-displayer img {
    max-height: none;
    height: auto;
  }

  .bottom-text-trainings-displayer {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .sub-container-trainings-displayer {
    width: 90%;
  }
}
