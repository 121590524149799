.main-container-privacypolicy {
  width: 100%;
}

/* TOP BANNER */ 

.container-banner-privacypolicy {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.container-banner-privacypolicy h2 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #333333;
}

.container-banner-privacypolicy svg {
  font-size: 35px;
  color: #006caa;
  padding: 20px;
} 

.subtitle-banner-privacypolicy {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
  text-align: center;
}  

.subtitle-banner-privacypolicy h4 {
  font-family: 'Montserrat';
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
}

.sub-container-privacypolicy {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-container-privacypolicy {
  width: 80%;
}

.title-banner-privacypolicy {
  color: white;
  font-style: MontSerrat;
  font-size: 52px;
  text-transform: uppercase;
  font-weight: 600;
}


.title-content-privacypolicy h2 {
  text-transform: uppercase;
  font-size: 20px;
  font-family: MontSerrat;
  font-weight: 700;
  color: #333;
}

.title-content-privacypolicy h3 {
  font-size: 16px;
  font-family: MontSerrat;
  color: #999999;
}

.content-privacypolicy p {
  font-size: 16px;
  font-family: MontSerrat;
  color: #777777;
  margin: 0px;
  font-weight: 500;
  line-height: 25px;
}

.last-child-content-privacypolicy {
  padding-bottom: 100px;
}

/* media queries */

@media (max-width: 800px) {
  .content-container-privacypolicy {
    width: 90%;
  }
}

@media (max-width: 700px) {
  .title-content-presentation h2 {
    font-size: 20px;
  }
}

@media (max-width: 1000px) {
  .title-banner-privacypolicy {
    font-size: 42px;
  }
}

@media (max-width: 800px) {
  .title-banner-privacypolicy {
    font-size: 32px;
  }
} 

@media (max-width: 500px) {
  .title-banner-privacypolicy {
    font-size: 18px;
  }

  .banner-container-privacypolicy {
    height: 178px;
  }
}